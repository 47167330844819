@font-face {
    font-family: 'MyWebFont';
    src: url('../../assets/fonts/supercell-magic-webfont.woff') format('woff');
}

.container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-family: 'MyWebFont';
        text-shadow: 0px 2px 0 #000;
        -webkit-text-stroke: 1px #0b0b0b;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.9px;
        text-align: center;
        color: #fff;
    }
}
