@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face {
    font-family: 'supercellmagic-regular';
    src: url(../src/assets/fonts/supercell-magic-webfont.woff) format('woff');
}

body {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1800px;
        padding-left: 120px;
        padding-right: 120px;
    }
}

body {
    overflow-x: hidden;
    font-family: 'Rubik', sans-serif !important;
    position: relative;
    img {
        max-width: 100%;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'supercellmagic-regular';
    }
    .devilflip-h2-heading {
        font-weight: 400;
        margin-bottom: 20px;
        transition: all 1s ease-in-out 0.7s;
        text-transform: capitalize;
        font-family: 'supercellmagic-regular';
        font-size: 46px;
        -webkit-text-fill-color: transparent;
        text-shadow: 0px -1px 1px #2b80ff, 0px 1.5px 0px #000, 0 0 0 #fff;
        -webkit-text-stroke: 1.3px #000;
        letter-spacing: 1px;
    }
    .devilflip-h3-heading {
        font-size: 40px;
        line-height: 1.3;
    }
    .devilflip-section {
        margin: 0px 0px;
    }
    .devilflip-paragraph {
        font-size: 22px;
        color: #282828;
        line-height: 1.5;
        width: 91%;
        margin-top: 25px;
    }
    .devilflip-section-image {
        width: 100%;
        max-width: 840px;
    }
    .devilflip-heading-primay-color {
        text-shadow: 0px -1px 1px #d5504c, 0px 1.5px 0px #000, 0 0 0 #fff;
        margin-top: 15px;
        display: inline-block;
    }
    .mobile-friendly-devilflip-paragraph {
        color: #fff;
        line-height: normal;
        font-weight: 300;
        font-size: 26px;
    }
}

section {
    padding-top: 140px;
    padding-bottom: 140px;
}
.title-img {
    margin-bottom: 30px;
}
.title-img img {
    max-width: 100%;
    max-height: 50px;
    display: block;
    margin-bottom: 15px;
}
.title-img.text-center img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.title-img.title-img-2 img {
    max-height: 46px;
}
.title-img.text-center {
    margin-bottom: 70px;
}
html .custom-col-1 {
    width: 46%;
}
html .custom-col-2 {
    width: 54%;
}

@media (min-width: 1301px) and (max-width: 1450px) {
    body {
        .container,
        .container-lg,
        .container-md,
        .container-sm,
        .container-xl,
        .container-xxl {
            padding-left: 100px;
            padding-right: 100px;
        }
    }
}

@media (min-width: 1200px) and (max-width: 1300px) {
    body .devilflip-h2-heading {
        font-size: 40px;
    }
    body .devilflip-paragraph {
        font-size: 20px;
        width: 100%;
    }
    .title-img img {
        max-height: 42px;
    }
    body {
        .container,
        .container-lg,
        .container-md,
        .container-sm,
        .container-xl,
        .container-xxl {
            padding-left: 70px;
            padding-right: 70px;
        }
    }
}

@media (max-width: 1199px) {
    body {
        .container,
        .container-lg,
        .container-md,
        .container-sm,
        .container-xl,
        .container-xxl {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    body {
        .devilflip-section-image {
            width: 100%;
        }
        .devilflip-paragraph {
            width: 100%;
        }
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    body {
        .devilflip-h2-heading {
            font-size: 32px;
        }
        .devilflip-tokenomics-mobile-h2-heading {
            font-size: 35px;
        }
    }
    body .devilflip-paragraph {
        font-size: 18px;
    }
    .title-img img {
        max-height: 38px;
    }
    .title-img.title-img-2 img {
        max-height: 36px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    body {
        .container,
        .container-lg,
        .container-md,
        .container-sm,
        .container-xl,
        .container-xxl {
            padding-left: 35px;
            padding-right: 35px;
        }
    }
    body {
        .devilflip-paragraph {
            font-size: 24px;
            margin-top: 15px;
        }
        .devilflip-tokenomics-mobile-h2-heading {
            font-size: 30px;
            -webkit-text-stroke: 1.5px #000;
        }
        .devilflip-h3-heading {
            font-size: 25px;
        }
        .mobile-friendly-devilflip-paragraph {
            font-size: 17px;
        }
    }
    section {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    body .devilflip-h2-heading {
        font-size: 44px;
        line-height: 100%;
        margin-bottom: 25px;
    }

    html .custom-col-1 {
        width: 100%;
    }
    html .custom-col-2 {
        width: 100%;
    }
    .title-img img {
        margin-bottom: 6px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 767px) {
    body {
        .container,
        .container-lg,
        .container-md,
        .container-sm,
        .container-xl,
        .container-xxl {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    body {
        .devilflip-paragraph {
            font-size: 12px;
            margin-top: 15px;
        }
        .devilflip-tokenomics-mobile-h2-heading {
            font-size: 30px;
        }
        .devilflip-h3-heading {
            font-size: 25px;
        }
        .mobile-friendly-devilflip-paragraph {
            font-size: 17px;
        }
    }
    section {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    body .devilflip-h2-heading {
        font-size: 20px;
        line-height: 90%;
        margin-bottom: 18px;
        -webkit-text-stroke: 1px #000;
    }
    body .devilflip-paragraph:last-child {
        margin-bottom: 0;
    }
    html .custom-col-1 {
        width: 100%;
    }
    html .custom-col-2 {
        width: 100%;
    }
    .title-img img {
        margin-bottom: 4px;
        margin-left: auto;
        margin-right: auto;
        max-height: 21px;
    }
    .title-img {
        margin-bottom: 15px;
    }
    .title-img.title-img-2 img {
        max-height: 20px;
    }
    .title-img.text-center {
        margin-bottom: 30px;
    }
    .footer-box-video {
        max-width: 150%;
        margin-right: -15px;
        margin-bottom: -25px;
        margin-left: -15px;
    }
}
