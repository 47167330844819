@import '/src/styles/variables/common';
@import '/src/styles/mixins/breakpoints';

.container {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;

    visibility: hidden;
    transition: visibility 0s 0.65s;

    &.containerVisible {
        transition: visibility 0s;
        visibility: visible;
    }
}

.bgMask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 0;

    animation: hide-mask 0.3s 0.3s ease-out forwards;

    .containerVisible & {
        animation: none;
    }
}

@keyframes hide-mask {
    to {
        opacity: 0;
    }
}

.bg {
    position: absolute;
    z-index: 999;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.75);

    animation: hide 0.3s 0.25s ease-out forwards;

    @include breakpoint('sm-max') {
        animation-name: hide-mobile;
    }

    .containerVisible & {
        animation: none;
    }

    p {
        font-family: 'MyWebFont';
        font-size: 20px;
        text-shadow: 0px 2px 0 #000;
        -webkit-text-stroke: 1px #0b0b0b;
        letter-spacing: 1px;
        color: #fff;
        margin: 0;
    }

    .ldsEllipsis {
        display: inline-block;
        position: relative;
        width: 40px;
        height: 65px;
    }

    .ldsEllipsis div {
        position: absolute;
        top: 33px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0px 2px 0 #000;
        -webkit-text-stroke: 1px #0b0b0b;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    .ldsEllipsis div:nth-child(1) {
        left: 4px;
        animation: lds-ellipsis1 0.6s infinite;
    }
    .ldsEllipsis div:nth-child(2) {
        left: 4px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .ldsEllipsis div:nth-child(3) {
        left: 16px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .ldsEllipsis div:nth-child(4) {
        left: 28px;
        animation: lds-ellipsis3 0.6s infinite;
    }
}

@keyframes hide {
    to {
        transform: translateX(100%);
    }
}

@keyframes hide-mobile {
    to {
        transform: translateY(-100%);
    }
}

.loader {
    position: relative;
    z-index: 2;
    animation: disappear 0.25s ease-out forwards;
    width: 128px;

    .containerVisible & {
        transform: scale(0.75);
        opacity: 0;
        animation: appear 0.25s 0.35s ease-in forwards;
    }
}

@keyframes appear {
    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes disappear {
    to {
        transform: scale(0.5);
        opacity: 0;
    }
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(16px, 0);
    }
}
