@import '/src/styles/variables/common';
@import '/src/styles/mixins/breakpoints';

.wrapper {
    padding-top: 43px;
    padding-bottom: 43px;
}

.heading {
    font-family: $font-primary;
    color: #303436;
    text-align: center;
    font-size: 35px;
    font-weight: 900;
    line-height: 1.14;
    margin-bottom: 20px;
    @include breakpoint('sm-max') {
        font-size: 20px;
    }
}

.text {
    font-family: $font-primary;
    color: #495357;
    text-align: center;
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 30px;
    line-height: 1.14;
    @include breakpoint('sm-max') {
        font-size: 15px;
    }
}

.icon {
    flex-shrink: 0;
    width: 21px;
    height: 21px;
    margin-right: 9px;
}

.logout {
    text-align: center;
    margin-top: 5px;
}

.button {
    border-radius: 40px;
    height: 70px;
    @include breakpoint('sm-max') {
        height: auto;
    }
    span {
        font-family: $font-primary;
        text-align: center;
        font-size: 23px;
        font-weight: 700;
        line-height: 1.14;
        @include breakpoint('sm-max') {
            font-size: 15px;
        }
    }
}

.spinner {
    display: flex;
    margin: 0 auto;
    width: 50px;
    margin-bottom: 20px;
    @include breakpoint('sm-max') {
        width: 35px;
    }
}
