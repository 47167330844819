    .devil-pumps-section {
        .devilish-tokenomics-box {
            .devilflip-Tokenomics-card-box {
                background-color: #fff;
                box-shadow:0 8px 40px 0 rgba(0,0,0,0.1);
                border-radius: 15px;
                padding: 25px ;
                border: 0px;
                height: 100%;
                display: block;
                .devilflip-Tokenomics-card-heading {
                    font-size: 14px;
                    color: #282828;
                }
                .devilflip-Tokenomics-card-paragraph {
                    font-size: 15px;
                    color: #282828;
                }
            }
        }
    }
    
    .devilflip-h2-heading.devilflip-tokenomics-mobile-h2-heading {
        font-size: 42px;
        margin-bottom: 65px;
    }
    .tokenomics-items {
        max-width: 950px;
        margin: 0 auto;
    }
    .devilflip-Tokenomics-card-box .icon img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
        width: auto;
    }
    .devilflip-Tokenomics-card-box .icon {
        width: 110px;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 12px;
    }
    .devilflip-Tokenomics-card-box .card-body {
        padding: 0;
    }






    @media (min-width:992px) and (max-width:1199px) {
        .devilflip-h2-heading.devilflip-tokenomics-mobile-h2-heading {
            font-size: 32px;
            margin-bottom: 50px;
        }
        .devil-pumps-section .devilish-tokenomics-box .devilflip-Tokenomics-card-box {
            padding: 15px;
        }
    }



    
    @media (min-width:768px) and (max-width:991px) {
       
        .devil-pumps-section .devilish-tokenomics-box .devilflip-Tokenomics-card-box {
            padding: 55px;
            height: auto;
            margin: 0 auto;
            max-width: 500px;
        }
        .devilflip-Tokenomics-card-box .icon {
            width: 170px;
            height: 170px;
            margin: 0 auto 25px;
        }
        .devil-pumps-section .devilish-tokenomics-box .devilflip-Tokenomics-card-box .devilflip-Tokenomics-card-heading {
            font-size: 24px;
            margin-bottom:15px;
        }
        .devil-pumps-section .devilish-tokenomics-box .devilflip-Tokenomics-card-box .devilflip-Tokenomics-card-paragraph {
            font-size: 22px;
        }


    }
    
    @media (max-width:767px){
        .devilflip-h2-heading.devilflip-tokenomics-mobile-h2-heading {
            font-size: 18px;
            margin-bottom: 30px;
        }
        .devil-pumps-section .devilish-tokenomics-box .devilflip-Tokenomics-card-box {
            border-radius: 9px;
            padding: 20px;
            height: auto;
            max-width: 230px;
            margin: 0 auto;
        }
        .devilflip-Tokenomics-card-box .icon {
            width: 80px;
            height: 80px;
        }
        .devil-pumps-section .devilish-tokenomics-box .devilflip-Tokenomics-card-box .devilflip-Tokenomics-card-heading {
            font-size: 11px;
        }
        .devil-pumps-section .devilish-tokenomics-box .devilflip-Tokenomics-card-box .devilflip-Tokenomics-card-paragraph {
            font-size: 12px;
        }



    }