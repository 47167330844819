.wrapperWalletMaster {
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-stretch: normal;
    font-family: 'Rubik';

    .container {
        border-radius: 10px;
        border: 1px solid #000;
        background-color: #fff;
        box-shadow: 0 -2px #000;

        .spaceContainer {
            padding: 17px 30px 11.5px 30px;

            .title {
                .wrapperTitle {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 17px;
                    letter-spacing: 0.88px;
                    text-shadow: 0.1px 0.5px 1.5px rgba(0, 0, 0, 0.34);
                    padding: 0 47px;

                    p {
                        padding: 0;
                        margin: 0;
                        margin-right: 6px;
                    }
                }
            }

            .content {
                display: grid;
                grid-gap: 10.5px;
                padding-top: 9.5px;
                font-size: 13.5px;
                font-style: normal;

                .contentItem {
                    display: flex;
                    grid-template-columns: 1fr 1fr;

                    .contentIcon {
                        width: 50%;
                        display: flex;
                        align-items: center;

                        svg {
                            width: 26px;
                            height: 26px;
                        }

                        .iconText {
                            width: 75%;
                            overflow: hidden;
                            white-space: nowrap;
                            margin-left: 8.5px;
                        }
                    }

                    .contentPrice {
                        width: 50%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                    }
                }
            }

            .action {
                display: grid;
                grid-template-columns: 1fr 1fr;
                padding-top: 9.5px;

                .devilflip button {
                    display: flex;
                    justify-content: center;
                    color: #fff;
                    font-size: 15px;
                    border: 2px #000 solid;
                    border-bottom: 3px #000 solid;
                    box-shadow: inset 1px 1px 2px 0 #ffffff33,
                        inset -2px 3px 2px 0 rgba(255, 255, 255, 0.1),
                        inset 0px 0px 3px 5px #f1eded0d;
                    border-radius: 10px;
                    width: 100%;

                    .mobile-friendly-join-chat-image {
                        width: 22px;
                    }
                    .mobile-friendly-coming-soon-image {
                        width: 28px;
                    }
                }

                .devil1 {
                    padding-left: 9px;
                    button {
                        background-color: #287eff;
                        &:hover {
                            background-color: #000;
                        }
                    }
                }

                .devil2 {
                    padding-right: 9px;
                    button {
                        background-color: #d8514d;
                        &:hover {
                            background-color: #000;
                        }
                    }
                }
            }
        }
    }
}
