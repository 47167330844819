.home-background-image {
    background: url(../../assets/images/top_section_background.png);
    background-size: cover;
    background-position: center center;
    .banner-img {
        margin: -70px -100px;
    }
    .top-section {
        .sticky-top {
            padding-top: 20px;
            position: initial;
        }
        .header-logo {
            img {
                width: 120px;
                height: auto;
            }
        }
        .navbar-nav {
            display: inline-block;
            .nav-link {
                display: inline-block;
                padding: 0px 40px;
                color: #fff;
                font-size: 18px;
                font-weight: 400;
                &:hover {
                    color: #fff;
                }
                &:first-child {
                    font-weight: 700;
                }

                @media (max-width: 1024px) {
                    padding: 0px 30px;
                }
            }
        }
        .header-join-chat-btn {
            button {
                border-radius: 9px;
                background-color: #287eff;
                border: 0px;
                color: #fff;
                min-width: 0;
                min-height: 0;
                padding: 10px 20px;
                box-shadow: inset 1px 1px 2px 0 #ffffff33,
                    inset -2px -2px 3px 0 #40404066,
                    inset 0px 0px 3px 5px #f1eded0d, -1px 0px 4px 0 #00000040;
                border: 2px #000 solid;
                border-bottom: 3px #000 solid;
                transition: all 0.5s;
                font-weight: 200;
                font-size: 13px;
                font-family: 'supercellmagic-regular';
                &:hover {
                    background-color: #d5504c;
                }
                .header-join-chat-text {
                    display: inline-block;
                    vertical-align: middle;
                    padding-left: 6px;
                }
                .header-join-chat-img {
                    width: 18px;
                }
            }
        }
    }
    .background-container {
        color: #fff;
        .devilFlip-background-header {
            font-size: 52px;
            font-weight: 700;
        }
        .devilFlip-background-paragraph {
            font-size: 21px;
            margin: 20px 0px 45px 0px;
            line-height: 190%;
            color: #fff;
        }
        .top-section-header-wecolme-mobile {
            display: none;
        }
        .devilFlip-background-btn-1 button,
        .devilFlip-background-btn-2 button {
            font-size: 18px;
            color: #22282a;
            padding: 0px 20px;
            min-height: 50px;
            border-radius: 7px;
            background-color: #fff;
            font-weight: 600;
            border: 0;
            box-shadow: inset 1px 1px 2px 0 #ffffff33,
                inset -2px -2px 3px 0 #40404066, inset 0px 0px 3px 5px #f1eded0d,
                -1px 0px 4px 0 #00000040;
            margin-bottom: 40px;
            transition: all 0.5s;
            &:hover {
                background-color: #d5504c;
                color: #fff;
            }
        }
        .devilFlip-background-btn-2 button:hover {
            background-color: #0089fe;
        }
        .devilFlip-background-btn-2 {
            button {
                color: #0089fe;
            }
        }
        .devilFlip-background-btn-1-image {
            width: 26px;
            margin-right: 12px;
        }
        .devilFlip-background-logo-content {
            font-size: 18px;
            b {
                font-weight: 500;
            }
            .devilFlip-background-binance {
                width: 22px;
                margin: 2px 6px 0px 15px;
                vertical-align: top;
            }
        }
    }
    .home-header-section {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 1800px;
        padding-left: 120px;
        padding-right: 120px;
        padding-top: 0;
        padding-bottom: 0;
    }
}

@media (min-width: 992px) {
    .home-background-image {
        .top-section {
            .header-join-chat-btn-navbar {
                display: none;
            }
            .mobile-icon {
                display: none;
            }
        }
        .header-background-top-section {
            padding-top: 70px;
        }
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .home-background-image .home-header-section {
        padding-left: 15px;
        padding-right: 15px;
    }
    .home-background-image .background-container .devilFlip-background-header {
        font-size: 40px;
    }
    .home-background-image
        .background-container
        .devilFlip-background-paragraph {
        font-size: 16px;
        margin: 18px 0px 35px 0px;
        line-height: 180%;
    }
    .home-background-image
        .background-container
        .devilFlip-background-btn-1
        button,
    .home-background-image
        .background-container
        .devilFlip-background-btn-2
        button {
        font-size: 15px;
        padding: 0px 15px;
        min-height: 46px;
        margin-bottom: 25px;
    }
    .home-background-image
        .background-container
        .devilFlip-background-logo-content {
        font-size: 15px;
    }
    .home-background-image .banner-img {
        margin: -10px -10px -10px -30px;
    }
}

@media (min-width: 1200px) and (max-width: 1300px) {
    .home-background-image .background-container .devilFlip-background-header {
        font-size: 48px;
    }
    .home-background-image
        .background-container
        .devilFlip-background-paragraph {
        font-size: 18px;
    }
    .home-background-image
        .background-container
        .devilFlip-background-btn-1
        button,
    .home-background-image
        .background-container
        .devilFlip-background-btn-2
        button {
        font-size: 16px;
    }
    .home-background-image .banner-img {
        margin: -50px -40px;
    }
    .home-background-image {
        .home-header-section {
            padding-left: 70px;
            padding-right: 70px;
        }
    }
}

@media (min-width: 1301px) and (max-width: 1450px) {
    .home-background-image {
        .home-header-section {
            padding-left: 100px;
            padding-right: 100px;
        }
    }
}

@media (max-width: 991.9px) {
    .home-background-image .home-header-section {
        padding-left: 35px;
        padding-right: 35px;
    }
    .home-background-image {
        .top-section {
            .desktop-join-chat {
                display: none;
            }
            .mobile-icon.mobile-menu-bar,
            .mobile-icon.mobile-menu-close {
                background-image: -webkit-linear-gradient(
                    90deg,
                    rgb(19, 96, 212) 0%,
                    rgb(40, 126, 255) 100%
                );
                border: 1px #000 solid;
                border-bottom: 2px #000 solid;
                transition: all 0.5s;
                box-shadow: inset 1px 1px 2px 0 #ffffff33,
                    inset -2px -2px 3px 0 #40404066,
                    inset 0px 0px 3px 5px #f1eded0d, -1px 0px 4px 0 #00000040;
                border-radius: 5px;
                width: 45px;
                height: 45px;
                padding: 0;
                img {
                    max-width: 58px;
                    height: auto;
                    width: 25px;
                }
            }
            .mobile-icon.mobile-menu-close {
                img {
                    max-width: 56px;
                    height: auto;
                    width: 21px;
                }
            }
            .mobile-icon.mobile-menu-close {
                background-image: linear-gradient(
                    355deg,
                    #ea2218 0%,
                    #da4f48 100%
                );
                position: fixed;
                right: 27px;
                top: 38.5px;
            }
            .mobile-menu-section {
                .mobile-responsive-menu {
                    position: fixed;
                    top: 0;
                    right: -100%;
                    min-width: 300px;
                    opacity: 0;
                    z-index: -1;
                    height: 100%;
                    padding-top: 120px;
                    background-color: #fff;
                    text-align: left;
                    transition: all 0.8s;
                    visibility: hidden;
                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: -500%;
                        width: 500%;
                        height: 100%;
                        background-color: #000000b3;
                        z-index: -999999;
                    }
                    .nav-link {
                        color: #282828;
                        display: block;
                        padding: 20px 30px;
                        font-size: 18px;
                    }
                }
                &.responsive-nav-container {
                    .mobile-responsive-menu {
                        opacity: 1;
                        visibility: visible;
                        z-index: -1;
                        right: 0;
                    }
                }
                .header-join-chat-btn-navbar {
                    display: block;
                    padding-left: 25px;
                    margin-top: 30px;
                    .header-join-chat-btn {
                        button {
                            border: 1.5px #000 solid;
                            border-bottom: 2px #000 solid;
                            min-width: 175px;
                        }
                    }
                }
            }
        }
    }
    .home-background-image
        .background-container
        .top-section-header-wecolme-mobile {
        display: block;
        margin: 0 -30px -20px;
    }
    .header-background-top-section {
        padding-top: 100px;
        padding-bottom: 30px;
    }
    .home-background-image .background-container .devilFlip-background-header {
        font-size: 58px;
    }
    .home-background-image
        .background-container
        .devilFlip-background-paragraph {
        font-size: 22px;
        margin: 0 0px 40px 0px;
        line-height: 180%;
    }
    .home-background-image
        .background-container
        .devilFlip-background-btn-1
        button,
    .home-background-image
        .background-container
        .devilFlip-background-btn-2
        button {
        font-size: 20px;
        padding: 0px 20px;
        min-height: 56px;
    }
    .home-background-image
        .background-container
        .devilFlip-background-logo-content {
        font-size: 22px;
    }
    .top-section-header-wecolme-desktop {
        display: none;
    }
}

@media (max-width: 767px) {
    .home-background-image .top-section .header-logo img {
        width: 65px;
    }
    .home-background-image .home-header-section {
        padding-left: 15px;
        padding-right: 15px;
        position: fixed;
        z-index: 99;
        background: -webkit-linear-gradient(
            top,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.62) 62%,
            rgba(0, 0, 0, 0) 100%
        );
    }
    .home-background-image .top-section .sticky-top {
        padding-top: 14px;
    }
    .home-background-image .background-container .devilFlip-background-header {
        font-size: 27px;
    }
    .home-background-image .top-section .mobile-icon.mobile-menu-close {
        right: 15px;
        top: 19px;
    }
    .home-background-image
        .top-section
        .mobile-menu-section
        .mobile-responsive-menu {
        min-width: 220px;
        padding-top: 90px;
        padding-bottom: 30px;
    }
    .home-background-image
        .background-container
        .top-section-header-wecolme-mobile {
        margin: -10px -10px -30px;
    }
    .home-background-image
        .background-container
        .devilFlip-background-paragraph {
        font-size: 11px;
        margin: 0 0px 18px 0px;
        line-height: 150%;
    }
    .home-background-image
        .background-container
        .devilFlip-background-btn-1
        button,
    .home-background-image
        .background-container
        .devilFlip-background-btn-2
        button {
        font-size: 10px;
        padding: 0px 6px;
        min-height: 32px;
        border-radius: 4px;
        margin-bottom: 10px;
    }
    .home-background-image
        .background-container
        .devilFlip-background-btn-1-image {
        width: 17px;
        margin-right: 6px;
    }
    .home-background-image
        .background-container
        .devilFlip-background-logo-content {
        font-size: 11px;
    }
    .home-background-image
        .background-container
        .devilFlip-background-logo-content
        .devilFlip-background-binance {
        width: 14px;
        margin: 2px 6px 0px 8px;
    }
    .header-background-top-section {
        padding-bottom: 0;
        padding-top: 80px;
    }
    .home-background-image
        .top-section
        .mobile-menu-section
        .mobile-responsive-menu
        .nav-link {
        padding: 15px 30px;
    }
}
