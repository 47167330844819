.content {
  max-width: 1280px;
  box-sizing: border-box;
  margin: 0 auto;
  padding-left: 48px;
  padding-right: 48px;

  @include breakpoint("md-max") {
    padding-left: 23px;
    padding-right: 23px;
  }
}