@font-face {
    font-family: 'MyWebFont';
    src: url('../../assets/fonts/supercell-magic-webfont.woff') format('woff');
}

.container {
    padding: -20px;

    .wrapperDialog {
        font-family: 'MyWebFont';
        width: 467px;
        object-fit: contain;
        border-radius: 15px;
        box-shadow: 0px -2px 0 0 #000;
        border: solid 1px #0b0b0b;
        background-color: #fff;

        @media (max-width: 1024px) {
            zoom: 0.9;
        }
        @media (max-width: 568px) {
            zoom: 0.7;
        }

        @media (max-width: 414px) {
            width: 100%;
            margin: 0 10px;
        }

        .wrapperTitle {
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: linear-gradient(to top, #62687a, #717885);
            box-shadow: 0px -2px 0 0 #000;
            border-bottom: solid 1px #0b0b0b;
            border-radius: 15px 15px 0 0;
            transform: translate(0px, -1px);

            p {
                text-shadow: 0px 2px 0 #000;
                -webkit-text-stroke: 1px #0b0b0b;
                font-size: 20px;
                letter-spacing: 1px;
                text-align: center;
                color: #fff;
                margin: 0 !important;
                padding: 16.5px 0;
            }

            .wrapperClose {
                position: absolute;
                top: 19.5;
                right: 12.5px;
                cursor: pointer;
            }
        }

        .wrapperContent {
            padding: 12.5px;
        }
    }
}
