@font-face {
    font-family: 'MyWebFont';
    src: url('../../assets/fonts/supercell-magic-webfont.woff') format('woff'),
}

.container {
    margin: auto;

    .span {
        font-family: 'MyWebFont';
        display: flex;
        justify-content: center;
        -webkit-text-stroke: 1px #0b0b0b;
        text-shadow: 0px 2px 0 #000;
        font-size: 50px;
        font-weight: normal;
        font-style: normal;
        letter-spacing: 2.5px;
        text-align: center;
        color: #fff;
    }

    .wrapperChildNode {
        display: flex;
        justify-content: center;
        padding-top: 19.5px;
    }
}