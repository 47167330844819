@import '/src/styles/variables/common';
@import '/src/styles/mixins/breakpoints';

.wallet {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 7.5px;
    background-color: #232323;
    border: solid 1px #0b0b0b;
    box-shadow: 0px 2px 0 0 #000, inset 0 1px 0 0 #525252,
        inset 0 2px 0 0 #4e4e4e, inset 0 3px 0 0 #464646,
        inset 0 4px 0 0 #3e3e3e, inset 0 5px 0 0 #2d2d2d,
        inset 0 6px 0 0 #282828, inset 0 7px 0 0 #252525,
        inset 0 8px 0 0 #242424, inset 0 9px 0 0 #232323,
        inset -2px -2px 3px 0 #40404066, inset 0px 0px 3px 5px #f1eded0d,
        -1px 0px 4px 0 #00000040;
    height: 39px;
    padding: 0 2px 0 12px;

    cursor: pointer;

    @include breakpoint('sm-max') {
        border-radius: 5px;
        padding: 0 0 0 7px;
        height: 24px;
    }

    @include breakpoint('xl-min') {
        border-radius: 9px;
        height: 40px;
        padding: 0 3px 0 15px;
    }

    // @media (max-width: 1024px) {
    //     height: 32px;
    // }

    @media (max-width: 1024px) and (max-height: 442px) {
        height: 28px;
    }
}

.walletBalance,
// /font-size: 12.5px;
// font-weight: normal;
// font-stretch: normal;
// font-style: normal;
// line-height: 4.32;
// letter-spacing: normal;
.walletAddress {
    font-family: $font-primary;
    font-size: 12.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 4.32;
    letter-spacing: normal;
    text-align: right;
    color: #fff;
    white-space: nowrap;

    @include breakpoint('sm-max') {
        font-size: 8.5px;
    }

    @include breakpoint('xl-min') {
        font-size: 16px;
    }

    @media (max-width: 1024px) and (max-height: 442px) {
        font-size: 8.5px;
    }
}

.walletAddress {
    display: flex;
    align-items: center;
    height: 33px;
    font-size: 12px;
    background: #287eff;
    box-shadow: inset 1px 1px 2px 0 #ffffff33, inset -2px -2px 3px 0 #40404066,
        inset 0px 0px 3px 5px #f1eded0d, -1px 0px 4px 0 #00000040;
    border-radius: 5px;
    padding: 0 10px;
    color: #fff;

    margin-left: 10px;

    @include breakpoint('sm-max') {
        padding: 0 5px 0 5px;
        margin-left: 5px;
        height: 20px;
        border-radius: 5px;
        font-size: 8.5px;
    }

    @include breakpoint('xl-min') {
        border-radius: 6px;
        height: 34px;
    }

    @media (max-width: 1024px) and (max-height: 442px) {
        height: 24px;
        font-size: 8.5px;
    }
}

.walletIcon {
    display: flex;
    margin-left: 10px;
    border-radius: 50%;

    @include breakpoint('sm-max') {
        margin-left: 4px;
        margin-bottom: 2px;
    }
}
