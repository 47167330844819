.wrapperEmotion {
    position: relative;

    p {
        margin: 0;
        padding: 0;
    }

    .contentTitle {
        font-family: 'MyWebFont';
        font-stretch: normal;
        font-style: normal;
        text-align: center;
        position: absolute;
        top: 70%;
        right: 0;
        left: 0;
        text-shadow: 0px 2px 0 #000;
        -webkit-text-stroke: 1px #0b0b0b;

        .headTitleAngel {
            padding-bottom: 16.5px;
            font-size: 22.5px;
            color: #287eff;
        }

        .headTitleDevil {
            padding-bottom: 16.5px;
            font-size: 22.5px;
            color: #d8514d;
        }

        .price {
            display: grid;
            font-size: 15px;
            line-height: 2;
            letter-spacing: 0.75px;
            text-align: center;

            p {
                color: #fefefe;
            }
        }

        // @media (max-width: 1024px) {
        //     top: 58%;
        // }

        @media (max-width: 1024px) {
            top: 62%;
        }

        @media (max-width: 1024px) and (max-height: 768px) {
            top: 62.5%;
        }

        // @media (max-width: 1024px) {
        //     top: 63%;
        // }

        @media (max-width: 767px) {
            top: 57%;
        }

        @media (max-width: 823px) and (max-height: 441px) {
            top: 65%;
        }

        @media (max-width: 568px) and (max-height: 320px) {
            top: 70%;
        }

        @media (max-width: 414px) {
            top: 57%;
        }

        // @media (max-width: 320px) {
        //     top: 57%;
        // }
    }

    .devilflip {
        display: flex;
        justify-content: center;
        padding-top: 17px;

        .activeBtn {
            width: 110px;
            animation-name: example;
            animation-duration: 1s;
            animation-delay: 2.5s;
        }

        @keyframes example {
            from {transform: scale(1)}
            to {transform: scale(1.1)}
        }
    }

    .devilflip button {
        display: flex;
        justify-content: center;
        width: 112.5px;
        padding: 6.5px 0;
        color: #fff;
        font-family: 'Rajdhani';
        font-weight: bold;
        font-size: 20px;
        border: 2px #000 solid;
        -webkit-text-stroke: 0px;
        border-bottom: 3px #000 solid;
        box-shadow: inset 1px 1px 2px 0 #ffffff33,
            inset -2px 3px 2px 0 rgba(255, 255, 255, 0.1),
            inset 0px 0px 3px 5px #f1eded0d;
        border-radius: 10px;
        pointer-events: none;

        .mobile-friendly-join-chat-image {
            width: 22px;
        }
        .mobile-friendly-coming-soon-image {
            width: 28px;
        }
    }

    .devil1 button {
        background-color: #287eff;
    }

    .devil2 button {
        background-color: #d8514d;
    }
}
