@import '/src/styles/mixins/breakpoints';
@import '/src/styles/variables/common';

.copy {
    font-family: $font-primary;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.08px;
    text-align: left;
    color: #4f4f4f;

    img {
        margin-right: 5px;
    }
}

.wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.tooltip {
    :global {
        .ant-tooltip-inner {
            border-radius: 10px;
            padding: 10px;
            min-height: auto;
            line-height: 1;
            color: #fff;
            background: #000;
            font-family: $font-primary;
        }

        .ant-tooltip-arrow-content {
            background: #000;
        }
    }
}

.icon {
    width: auto;
    height: auto;
}
