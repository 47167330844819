@import '/src/styles/variables/common';
@import '/src/styles/mixins/breakpoints';

.wrapper {
    padding-top: 43px;
    padding-bottom: 43px;
}

.heading {
    margin: 0;

    font-family: $font-primary;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.72;
    letter-spacing: normal;
    text-align: left;
    color: #22282a;
}

.data {
    border: 1px solid #ebebeb;
    padding: 15px;
    border-radius: 7.5px;
    margin: 30px 0 0;
}

.label {
    font-family: $font-primary;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #22282a;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dataButton {
    font-size: 11px;
    padding: 4px 5px !important;
    min-height: 0;
    color: #adafae !important;
    border-color: #adafae !important;
    border-radius: 5px;
}

.address {
    display: flex;
    align-items: center;

    font-family: $font-primary;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #22282a;

    margin-top: 12px;
}

.icon {
    flex-shrink: 0;
    width: 21px;
    height: 21px;
    margin-right: 9px;
}

.logout {
    text-align: center;
    margin-top: 5px;

    button {
        background-color: #0077ff;
        color: #ffffff;
        transition: opacity 0.15s ease-in;
        padding: 0 19px;
        min-height: 30px;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 0.55px;
        border-radius: 2.5px;
        line-height: 9px;
    }
}

.transaction {
    margin-top: 22px;
}

.transactionHead {
    font-family: $font-primary;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #22282a;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.clearButton {
    font-size: 13px;
    color: #ababab;
    background: none;
}

.transactionList {
    list-style: none;
    padding: 0;
    margin: 15px 0 0;
}

.transactionItem {
    font-family: $font-secondary;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #22282a;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:first-child) {
        margin-top: 12px;
    }
}

.checkIcon {
    width: 17px;
}

.dataActions {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.dataAction {
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 12px;
    color: #adafae;

    &:not(:first-child) {
        margin-left: 40px;
    }
}

.dataActionIcon {
    height: 13px;
    width: auto;
}
