.footer-section {
    .come-dance-devil-background {
        background-image: -webkit-linear-gradient(
            209deg,
            #ffffff 0%,
            #ff0b0b 0%,
            #960000 30%,
            #003596 70%,
            #02c9ff 95%,
            #02c9ff 100%
        );
        border-radius: 10px;
        margin-bottom: 70px;
        .come-dance-devil-text-part {
            color: #fff;
            margin-left: 12%;
            .devilflip-come-dance-devil-btn button {
                color: #fff;
                font-size: 16px;
                font-family: 'supercellmagic-regular';
                border: 2px #000 solid;
                border-bottom: 3px #000 solid;
                min-height: 56px;
                box-shadow: inset 1px 1px 2px 0 #ffffff33,
                    inset -2px 3px 2px 0 rgba(255, 255, 255, 0.1),
                    inset 0px 0px 3px 5px #f1eded0d;
                border-radius: 10px;
                padding: 0px 25px;
                .mobile-friendly-join-chat-image {
                    width: 22px;
                }
                .mobile-friendly-coming-soon-image {
                    width: 28px;
                }
            }
            .come-dance-devil-1 button {
                background-color: #287eff;
                &:hover {
                    background-color: #000;
                }
            }
            .come-dance-devil-2 button {
                background-color: #d8514d;
                &:hover {
                    background-color: #000;
                }
            }
        }
        .come-dance-devil-image {
            width: auto;
            display: block;
            margin: 8px auto -25px;
            max-height: 380px;
        }
        .devilflip-h3-heading {
            color: #fff;
            font-size: 32px;
        }
        .mobile-friendly-devilflip-paragraph {
            color: #fff;
            line-height: normal;
            font-weight: 400;
            font-size: 18px;
        }
    }
    .footer-devil-border {
        border-bottom: 1px #d6dadd solid;
        padding-bottom: 30px;
        .footer-logo {
            width: 125px;
            height: auto;
        }
        .footer-link {
            font-size: 18px;
            text-decoration: none;
            padding: 0px 20px;
            font-weight: 400;
            color: #28292a;
            &:hover {
                color: #287eff;
            }
        }
        .footer-custom-class {
            max-width: 231px;
            margin-left: auto;
        }
        .footer-follow-us-on {
            font-size: 20px;
            font-weight: 400;
            color: #28292a;
            line-height: 120%;
        }
    }
}

.footer-section {
    padding-top: 80px;
    padding-bottom: 0;
}
.footer-social-wrap .btn {
    padding: 0;
    width: 30px;
    transition: all 0.5s;
    &:hover {
        transform: scale(1.1);
    }
}
.footer-social-wrap .btn + .btn {
    margin-left: 15px;
}
.footer-social-wrap {
    margin-top: 14px;
}

.footer-box-video {
    max-width: 440px;
    margin-right: auto;
    margin-bottom: -30px;
}

@media (min-width: 1301px) and (max-width: 1450px) {
    .footer-section .come-dance-devil-background .devilflip-h3-heading {
        font-size: 28px;
    }
    .footer-box-video {
        max-width: 380px;
        margin-left: 30px;
    }
    .footer-section .come-dance-devil-background .come-dance-devil-text-part {
        margin-left: 13%;
    }
}

@media (min-width: 1200px) and (max-width: 1300px) {
    .footer-section .come-dance-devil-background .devilflip-h3-heading {
        font-size: 28px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .footer-section .come-dance-devil-background .devilflip-h3-heading {
        font-size: 25px;
    }
    .footer-section
        .come-dance-devil-background
        .come-dance-devil-text-part
        .devilflip-come-dance-devil-btn
        button {
        font-size: 14px;
    }
    .footer-section .footer-devil-border .footer-link {
        font-size: 16px;
    }
    .footer-section .footer-devil-border .footer-follow-us-on {
        font-size: 18px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .footer-section .come-dance-devil-background .come-dance-devil-text-part {
        margin-left: 0;
    }
    .footer-section .come-dance-devil-background .devilflip-h3-heading {
        font-size: 34px;
        max-width: 500px;
        margin: 0 auto 10px;
    }
    .footer-section
        .come-dance-devil-background
        .mobile-friendly-devilflip-paragraph {
        font-size: 26px;
    }
    .footer-section
        .come-dance-devil-background
        .come-dance-devil-text-part
        .devilflip-come-dance-devil-btn
        button {
        font-size: 22px;
        min-height: 66px;
        padding: 0px 30px;
    }
    .footer-section
        .come-dance-devil-background
        .come-dance-devil-text-part
        .devilflip-come-dance-devil-btn
        button
        .mobile-friendly-coming-soon-image {
        width: 38px;
    }
    .footer-section
        .come-dance-devil-background
        .come-dance-devil-text-part
        .devilflip-come-dance-devil-btn
        button
        .mobile-friendly-join-chat-image {
        width: 30px;
    }
    .footer-section .come-dance-devil-background .come-dance-devil-image {
        margin: 18px auto -25px;
        max-height: 560px;
    }
    .footer-box-video {
        max-width: 100%;
    }
}

@media (max-width: 767px) {
    .footer-section {
        padding-top: 40px;
    }
    .footer-section .come-dance-devil-background {
        border-radius: 7px;
        margin-bottom: 30px;
        padding: 15px;
    }
    .footer-section .come-dance-devil-background .come-dance-devil-text-part {
        margin-left: 0;
    }
    .footer-section .come-dance-devil-background .devilflip-h3-heading {
        font-size: 16px;
        max-width: 210px;
        margin: 10px auto 0px;
    }
    .footer-section
        .come-dance-devil-background
        .mobile-friendly-devilflip-paragraph {
        font-size: 11px;
    }
    .footer-section
        .come-dance-devil-background
        .come-dance-devil-text-part
        .devilflip-come-dance-devil-btn
        button {
        font-size: 9px;
        min-height: 38px;
        border-radius: 6px;
        padding: 0px 10px;
    }
    .footer-section
        .come-dance-devil-background
        .come-dance-devil-text-part
        .devilflip-come-dance-devil-btn
        button
        .mobile-friendly-join-chat-image {
        width: 14px;
    }
    .footer-section
        .come-dance-devil-background
        .come-dance-devil-text-part
        .devilflip-come-dance-devil-btn
        button
        .mobile-friendly-coming-soon-image {
        width: 17px;
    }

    .footer-section .footer-devil-border .footer-link {
        font-size: 15px;
        padding: 0px 10px;
    }
    .footer-section .footer-devil-border {
        border-bottom: 0;
        padding-bottom: 0;
    }
    .footer-section .footer-devil-border .footer-logo {
        width: 66px;
    }
    .footer-section .footer-devil-border .footer-follow-us-on {
        font-size: 14px;
    }
    .footer-social-wrap .btn {
        width: 24px;
    }
    .footer-social-wrap .btn + .btn {
        margin-left: 12px;
    }
    .footer-social-wrap {
        margin-top: 8px;
    }
}
