@import '/src/styles/mixins/breakpoints';
@import '/src/styles/variables/common';

@mixin boxShape {
    padding: 14px;
    border-radius: 7.5px;
    border: 1px solid $color-border;
    min-height: 57px;
}

.container {
    list-style: none;
    margin: 0;
    padding: 0;
}

.item {
    margin: 0;
    padding: 0;

    &:not(:first-child) {
        margin-top: 12px;
    }
}

.button {
    @include boxShape;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: transparent;
    cursor: pointer;

    transition: $transition-all;

    &:hover:not(.selected &) {
        border-color: $color-blue;
    }

    &:focus {
        outline: none;
    }

    .selected & {
        cursor: default;
    }
}

.textGroup {
    font-family: $font-primary;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
}

.name {
    display: block;
    font-size: 15px;
    font-weight: 500;
}

.description {
    display: block;
    font-size: 12px;
    line-height: 1.5;
}

.picture {
    max-width: 27px;
    max-height: 33px;
}

.errorBox,
.requestingBox {
    @include boxShape;
    display: flex;
    align-items: center;

    margin-bottom: 15px;

    font-family: $font-primary;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
}

.errorBox {
    color: $color-red;
    border-color: $color-red;
}

.errorButton {
    margin-left: 13px;
}

.spinner {
    animation: rotate 1.5s linear infinite;
    margin-right: 13px;
    width: 14px;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
