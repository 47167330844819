.container {
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: center;
    padding: 100px 0;

    .spaceContainer {
        margin: auto;
    }

    .circle {
        width: 126px;
        height: 126px;
        justify-content: center;
        display: flex;
        align-items: center;
        background-color: #fff;
        border-radius: 50%;
        border: 1px solid #0b0b0b;
        box-shadow: 0px 3px 0 #000;

        .outline {
            width: 80px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #0b0b0b;
            border-radius: 50%;
            background-image: linear-gradient(to right, #ff0b0b, #02c9ff);
            padding-left: 1px;

            .subCircle {
                width: 85%;
                height: 85%;
                background-color: #fff;
                padding: 22% 25%;
                border: 1px solid #0b0b0b;
                border-radius: 50%;

                .wrapperDirectional {
                    width: 48px;
                    margin-top: -48px;
                    margin-left: -13px;
                }

                .wrapperCircle {
                    transform: translate(0px, -75px);
                }
            }
        }
    }

    .wrapperAction {
        position: absolute;
        display: flex;
        justify-content: center;
        padding-top: 40px;
        width: 125px;

        button {
            color: #282828;
            font-size: 12.5px;
            font-family: 'MyWebFont';
            box-shadow: 0px 2px 0 0 #000;
            border: solid 1px #0b0b0b;
            background-color: #fff;
            border-radius: 10px;
            width: 100px;
            padding: 13px 0;
            opacity: 1;
            transition: opacity 0.3 ease-out;

            .mobile-friendly-join-chat-image {
                width: 22px;
            }
            .mobile-friendly-coming-soon-image {
                width: 28px;
            }
        }
    }

    .disable button {
        opacity: 0;
        pointer-events: none;
    }
}

.default {
    transform: rotate(0deg) translate(0px, -38px);
}

.balance {
    animation: spinnerBalance 0.5s infinite linear;
}

.left {
    animation: spinnerLeft 4.5s linear forwards;
}

.right {
    animation: spinnerRight 4.5s linear forwards;
}

@keyframes spinnerBalance {
    0% {
        transform: rotate(0deg) translate(0px, -38.5px);
    }
    100% {
        transform: rotate(360deg) translate(0px, -38.5px);
    }
}

@keyframes spinnerLeft {
    0% {
        -webkit-transform: rotate(0deg) translate(0px, -38.5px);
    }
    10% {
        -webkit-transform: rotate(360deg) translate(0px, -38.5px);
    }
    20% {
        -webkit-transform: rotate(720deg) translate(0px, -38.5px);
    }
    40% {
        -webkit-transform: rotate(1080deg) translate(0px, -38.5px);
    }
    70% {
        -webkit-transform: rotate(1440deg) translate(0px, -38.5px);
    }
    100% {
        -webkit-transform: rotate(1710deg) translate(0px, -38.5px);
    }
}

@keyframes spinnerRight {
    0% {
        -webkit-transform: rotate(0deg) translate(0px, -38.5px);
    }
    10% {
        -webkit-transform: rotate(360deg) translate(0px, -38.5px);
    }
    20% {
        -webkit-transform: rotate(720deg) translate(0px, -38.5px);
    }
    40% {
        -webkit-transform: rotate(1080deg) translate(0px, -38.5px);
    }
    75% {
        -webkit-transform: rotate(1440deg) translate(0px, -38.5px);
    }

    100% {
        -webkit-transform: rotate(1530deg) translate(0px, -38.5px);
    }
}
