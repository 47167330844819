@import '/src/styles/variables/common';
@import '/src/styles/mixins/breakpoints';

.wrapperContentLayout {
    position: relative;
}

.iconHelper {
    cursor: pointer;

    svg {
        margin-left: 7px;
    }
}

.wrapperContent {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(100vh - 67px);

    @media (max-width: 1024px) {
        zoom: 0.8;
        height: 100%;
    }

    @media (max-width: 824px) and (max-height: 441px) {
        zoom: 0.45;
    }

    @media (max-width: 768px) and (max-height: 441px) {
        zoom: 0.6;
    }

    @media (max-width: 736px) {
        zoom: 0.5;
    }

    @media (max-width: 640px) and (max-height: 320px) {
        zoom: 0.3;
    }

    @media (max-width: 414px) {
        zoom: 0.7;
    }

    @media (max-width: 375px) {
        zoom: 0.6;
    }

    @media (max-width: 320px) {
        zoom: 0.5;
    }
}

.wrapperCountDown {
    position: absolute;
    top: 48.5px;
    z-index: 3;
}
.wrapperMasterWallet {
    position: absolute;
    bottom: 0;
    z-index: 3;
}

.wrapperBackground {
    display: flex;
}

.wrapperSpinner {
    position: absolute;
    top: 0;
    right: 50%;
    bottom: 0;
    left: 50%;
    z-index: 4;

    @media (max-width: 1024px) {
        zoom: 0.9;
    }

    @media (max-width: 823px) and (max-height: 441px) {
        zoom: 0.5;
        height: 100%;
    }

    @media (max-width: 768px) and (max-height: 441px) {
        zoom: 0.5;
    }

    @media (max-width: 736px) {
        zoom: 0.4;
    }
}

.wrapperEmotionTitle {
    display: grid;
    position: absolute;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    top: 0;
    bottom: 0;
    z-index: 3;

    @media (max-width: 1024px) {
        zoom: 1;
    }

    @media (max-width: 823px) and (max-height: 441px) {
        zoom: 0.45;
        height: 100%;
    }

    @media (max-width: 768px) and (max-height: 441px) {
        zoom: 0.6;
    }

    @media (max-width: 731px) {
        zoom: 0.5;
    }

    @media (max-width: 640px) {
        zoom: 0.4;
    }

    @media (max-width: 414px) {
        zoom: 0.6;
    }

    @media (max-width: 375px) {
        zoom: 0.5;
    }

    @media (max-width: 320px) {
        zoom: 0.5;
    }
}
