.light {
  /* BG */

  --bg-page: #fff;

  /* FONT COLOR */

  --font-color-dark: #000;
  --font-color-medium: #eee;
  --font-color-red: #e94949;

  /* BORDER */

  --border-color-gray: #ddd;

  /* BUTTON */

  --button-color-disabled: #777;
  --button-color-blue: #4451ee;

  --button-bg-light: #fff;
  --button-bg-disabled: #ddd;
  --button-bg-blue: #4451ee;
  --button-bg-gradient: linear-gradient(to right, #f5477c, #3d4bee);

  --button-border-blue: #4451ee;
}