.wrapperEmoji {
    position: relative;
    width: 50%;
    height: 100%;

    p {
        margin: 0;
        padding: 0;
    }

    .wrapperBackground {
        height: calc(100vh - 67px);

        @media (max-width: 1024px) {
            height: calc(100vh - 67px);
        }

        @media (max-width: 765px) {
            height: calc(100vh - 75px);
        }
    }

    .emotion {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        top: 0;

        @media (max-width: 1024px) {
            zoom: 0.6;
        }

        @media (max-width: 823px) and (max-height: 441px) {
            zoom: 0.3;
            height: 100%;
        }

        @media (max-width: 765px) {
            zoom: 0.3;
        }

        @media (max-width: 360px) {
            zoom: 0.24;
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .title {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        top: 0;

        .contentTitle {
            font-family: 'MyWebFont';
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            text-align: center;
            padding-top: 600px;
            text-shadow: 0px 2px 0 #000;
            -webkit-text-stroke: 1px #0b0b0b;

            .headTitleAngel {
                padding-bottom: 16.5px;
                font-size: 22.5px;
                color: #287eff;
            }

            .headTitleDevil {
                padding-bottom: 16.5px;
                font-size: 22.5px;
                color: #d8514d;
            }

            .price {
                font-size: 15px;
                line-height: 2;
                letter-spacing: 0.75px;
                text-align: center;

                p {
                    color: #fefefe;
                    padding-bottom: 8px;
                }
            }

            .devilflip {
                display: flex;
                justify-content: center;
            }

            .devilflip button {
                display: flex;
                justify-content: center;
                width: 112.5px;
                padding: 6.5px 0;
                color: #fff;
                font-family: 'Rajdhani';
                font-size: 20px;
                border: 2px #000 solid;
                border-bottom: 3px #000 solid;
                box-shadow: inset 1px 1px 2px 0 #ffffff33,
                    inset -2px 3px 2px 0 rgba(255, 255, 255, 0.1),
                    inset 0px 0px 3px 5px #f1eded0d;
                border-radius: 10px;

                .mobile-friendly-join-chat-image {
                    width: 22px;
                }
                .mobile-friendly-coming-soon-image {
                    width: 28px;
                }
            }

            .devil1 button {
                background-color: #287eff;
                &:hover {
                    background-color: #000;
                }
            }

            .devil2 button {
                background-color: #d8514d;
                &:hover {
                    background-color: #000;
                }
            }
        }
    }
}
