.page-appear,
.page-enter {
  opacity: 0;
}
.page-appear-active,
.page-enter.page-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-out;
}

.page-exit,
.page-exit.page-exit-active {
  display: none;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 3;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms linear 250ms;
}

.fade-exit {
  opacity: 1;
  position: absolute;
  display: none;
  z-index: 0;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 125ms linear;
}