@font-face {
    font-family: 'MyWebFont';
    src: url('../../assets/fonts/supercell-magic-webfont.woff') format('woff');
}

.wrapperHistory {
    .historyItem {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0px 2px 0 0 rgba(0, 0, 0, 0.5);
        border: solid 1px #0b0b0b;
        background-image: linear-gradient(to top, #cacaca, #f2f2f2);
    }

    .devilflip {
        max-width: 90%;
    }

    .devilflip button {
        display: flex;
        justify-content: center;
        color: #fff;
        font-size: 12.5px;
        font-family: 'MyWebFont';
        border: 2px #000 solid;
        border-bottom: 3px #000 solid;
        box-shadow: inset 1px 1px 2px 0 #ffffff33,
            inset -2px 3px 2px 0 rgba(255, 255, 255, 0.1),
            inset 0px 0px 3px 5px #f1eded0d;
        text-shadow: 0px 2px 0 #000;
        -webkit-text-stroke: 1px #0b0b0b;
        border-radius: 10px;
        width: 100%;
        .mobile-friendly-join-chat-image {
            width: 22px;
        }
        .mobile-friendly-coming-soon-image {
            width: 28px;
        }
    }

    .devil1 button {
        background-color: #287eff;
    }

    .devil2 button {
        background-color: #d8514d;
    }

    .text {
        display: flex;
        align-items: center;
        text-align: start;
    }

    .paragraph {
        width: 100%;
        overflow: hidden;
        margin: 0 !important;
        text-shadow: 0px 2px 0 #000;
        -webkit-text-stroke: 1px #0b0b0b;
        font-size: 12.5px;
    }

    .paragraph1 {
        color: #287eff;
    }

    .paragraph2 {
        color: #da4f48;
    }

    .price {
        width: 160px;
        padding-left: 15%;
        text-align: end;
    }

    .textEnd {
        justify-content: flex-end;
    }

    .pt {
        margin-top: 7.5px;
    }
}
